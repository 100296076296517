import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import waIcon from "../../assets/img/logo-wa.png";
import logoB from "../../assets/img/logo-b.png";
import logo from "../../assets/img/logo.png";
import mobilelogoB from "../../assets/img/sanmarcos-alt-white.png";
import mobilelogo from "../../assets/img/sanmarcos-alt.png";
import fbIcon from "../../assets/img/icono-facebook.png";
import twIcon from "../../assets/img/icono-twitter.png";
import igIcon from "../../assets/img/icono-instagram.png";
import fbIconBlue from "../../assets/img/icono-facebook-hov.png";
import twIconBlue from "../../assets/img/icono-twitter-hov.png";
import igIconBlue from "../../assets/img/icono-instagram-hov.png";
import leaf from "../../assets/img/gas-san-marcos-icono-emisiones.png";
import money from "../../assets/img/gas-san-marcos-icono-fuentes.png";
import phone from "../../assets/img/logo-tel-blanco.png";
import nosotros from "../../assets/img/nosotros.png";
import estacionario from "../../assets/img/gas-san-marcos-servicios-estacionario.jpg";
import cilindros from "../../assets/img/gas-san-marcos-servicios-cilindros.jpg";
import vehicular from "../../assets/img/gas-san-marcos-servicios-vehicular.jpg";
import bannerFooter from "../../assets/img/gas-san-marcos-footer.jpg";


const Header = () => {

    const btnNav = React.useRef(null);
    const headerNav = React.useRef(null);

    useEffect(() => {
        changeMenuBg()
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const handleScroll = (event) => {
        changeMenuBg()
    };

    const handleNavBtn = () => {
        let menuText = headerNav.current.querySelectorAll('.menu-text');
        let whiteLogo = headerNav.current.querySelectorAll('.white-logo');
        let blueLogo = headerNav.current.querySelectorAll('.blue-logo');
        let togglerIcon = headerNav.current.querySelector('.navbar-toggler-icon');

        if (btnNav.current.classList.contains('collapsed')) {
            changeMenuBg()
        } else {
            headerNav.current.classList.add("bg-white");

            togglerIcon.classList.remove("white-toggler");
            togglerIcon.classList.add("blue-toggler");

            whiteLogo.forEach(item => { item.style.display = "none"; });
            blueLogo.forEach(item => { item.style.display = "block" });
            menuText.forEach(item => {
                item.classList.remove("text-white");
                item.classList.add("text-blue");
            });
        }
    };

    const changeMenuBg = () => {
        let menuText = headerNav.current.querySelectorAll('.menu-text');
        let whiteLogo = headerNav.current.querySelectorAll('.white-logo');
        let blueLogo = headerNav.current.querySelectorAll('.blue-logo');
        let togglerIcon = headerNav.current.querySelector('.navbar-toggler-icon');

        if (window.scrollY > 50) {
            headerNav.current.classList.add("bg-white");
            togglerIcon.classList.remove("white-toggler");
            togglerIcon.classList.add("blue-toggler");
            whiteLogo.forEach(item => { item.style.display = "none"; });
            blueLogo.forEach(item => { item.style.display = "inline-block" });

            menuText.forEach(item => {
                item.classList.remove("text-white");
                item.classList.add("text-blue");
            });
        } else {
            headerNav.current.classList.remove("bg-white");
            togglerIcon.classList.add("white-toggler");
            togglerIcon.classList.remove("blue-toggler");

            whiteLogo.forEach(item => { item.style.display = "inline-block"; });
            blueLogo.forEach(item => { item.style.display = "none" });
            menuText.forEach(item => {
                item.classList.add("text-white");
                item.classList.remove("text-blue");
            });
        }
    }

    return (
        <header className="container-fluid header" ref={headerNav} id="header-nav">
            <div className="container desktop-item">
                <div className="row">
                    <div className="col-md-12">
                        <div className="top-menu d-flex align-items-center justify-content-between">
                            <div>
                                <Link to={'/'}>
                                    <img src={logoB} className="logo white-logo" alt="" />
                                    <img src={logo} className="logo blue-logo" style={{ display: "none" }} alt="" />
                                </Link>
                            </div>
                            <div className="menu-box d-flex justify-content-end align-items-center">
                                <ul className="nav me-4 justify-content-end">
                                    <li className="nav-item">
                                        <Link to={'/gas-estacionario'} className="nav-link text-white fw-bold menu-text">
                                            ESTACIONARIO
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/cilindros'} className="nav-link text-white fw-bold menu-text">
                                            CILINDROS
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/sucursales'} className="nav-link text-white fw-bold menu-text">
                                            SUCURSALES
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/#nosotros'} className="nav-link text-white fw-bold menu-text">
                                            NOSOTROS
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex">
                                <a href="https://wa.me/5214491113915"><img src={waIcon} className="icon-wa me-2" alt="" /></a>
                                <p className="text-white fs-15 menu-text">
                                    Envíanos un mensaje a WhatsApp<br />
                                    o Llámanos al: <a href="tel:4491113915" className="fw-bold" >(44) 9111 3915</a>
                                </p>
                            </div>
                            <div className="social-box me-4 justify-content-end text-end">
                                <a href="https://www.facebook.com/GasSanMarcos">
                                    <img src={fbIcon} className="social-icon white-logo me-1" alt="" />
                                    <img src={fbIconBlue} className="social-icon blue-logo me-1" alt="" />
                                </a>
                                <a href="https://twitter.com/gassanmarcos">
                                    <img src={twIcon} className="social-icon white-logo me-1" alt="" />
                                    <img src={twIconBlue} className="social-icon blue-logo me-1" alt="" />
                                </a>
                                <a href="https://www.instagram.com/gassanmarcos/?hl=es">
                                    <img src={igIcon} className="social-icon white-logo" alt="" />
                                    <img src={igIconBlue} className="social-icon blue-logo" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg mobile-item">
                <div className="container-fluid">
                    <Link to={'/'} className="navbar-brand">
                        <img src={mobilelogoB} className="logo white-logo" alt="" />
                        <img src={mobilelogo} className="logo blue-logo" style={{ display: "none" }} alt="" />
                    </Link>
                    <button onClick={handleNavBtn} ref={btnNav} className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon white-toggler"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to={'/gas-estacionario'} className="nav-link text-white fw-bold menu-text">
                                    ESTACIONARIO
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={'/cilindros'} className="nav-link text-white fw-bold menu-text">
                                    CILINDROS
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/sucursales'} className="nav-link text-white fw-bold menu-text">
                                    SUCURSALES
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white fw-bold menu-text" href="#nosotros">NOSOTROS</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;