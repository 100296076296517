import waIcon from "../../assets/img/logo-wa.png";
import logoB from "../../assets/img/logo-b.png";
import phone from "../../assets/img/logo-tel-blanco.png";

const Footer = () => {
    return (<>
        <div className="footer bg-dark-grey-01" id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-12">
                        <img src={logoB} className="logo" alt="" />
                        <p className="mb-0 text-light-grey fs-10 m mt-5">Gas San Marcos. Todos los derechos reservados.</p>
                    </div>
                    <div className="col-md col-6">
                        <p className="text-white ls-25 fs-12 fw-bold">OFICINAS</p>
                        <p className="text-light-grey fs-12">
                            Carr. a Villa Hidalgo KM. 4+060<br />
                            Santa Cruz de la Presa<br />
                            Aguascalientes, AGS.<br />
                            México. 20317
                        </p>
                    </div>
                    <div className="col-md col-6">
                        <p className="text-white ls-25 fs-12 fw-bold">CONTACTO</p>
                        <p className="fs-12">
                            <a href="tel:4499764990">449 976 49 90</a><br />
                            <a href="mailto:info@gassanmarcos.com">info@gassanmarcos.com</a>
                        </p>
                        <a className="fs-12" href="https://www.gassanmarcos.com/avisos-de-privacidad.pdf"
                            target="_blank" rel="noreferrer">
                            Avisos de Privacidad
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright bg-dark-grey text-center">
            <div className="container">
                <div className="row">
                    <div className="col-md">
                        <p className="mb-0 text-white fs-10 fw-bold">GAS SAN MARCOS FORMA PARTE DE <a
                            href="https://www.gruporg.com/">GRUPO RG</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky-btn-footer bg-white w-100 text-center mobile-item ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p className="fw-bold fs-18 text-blue">
                            ¡Solicita tu recarga de Gas LP Hoy!
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-6">
                        <a href="https://wa.me/5214491113915" className="btn-cta wa d-flex align-items-center justify-content-center">
                            <img src={waIcon} className="icon-button me-2" alt="" />
                            <span>Envianos<br /> WhatsApp</span>
                        </a>
                    </div>
                    <div className="col-md-6 col-6">
                        <a href="#" className="btn-cta call d-flex align-items-center justify-content-center">
                            <img src={phone} className="icon-button me-2" alt="" />
                            <span>Llámanos</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Footer;