import axios from "axios";

const saveLead = data => {
    data.tipo = 'SanMarcos';
    data.token = "RnV0dXJpdGUyMDIw";
    data.client = 145;
    return axios.post(
        "https://omnia.futurite.com/api/save-lead-form",
        data
    );
};

export default {
    saveLead
};